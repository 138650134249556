<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-button v-if="isProject" type="primary" size="small" @click="add">新增吊车</el-button>
        <el-button type="primary" size="mini" @click="edit()">修改吊车</el-button>
        <el-button type="danger" size="mini" @click="del()">撤场</el-button>
      </div>
      <div class="search flex-center-v">
        <el-select v-model="SearchValue.machineLessor" placeholder="请选择机械租赁商" size="small" style="width: 200px;margin-right: 5px;" clearable>
          <el-option v-for="item in MachineList" :key="item.id" :label="item.name" :value="item.name"></el-option>
        </el-select>
<!--        <el-select v-model="SearchValue.machineType" placeholder="机械类型" size="small" style="width: 200px;margin-right: 5px;" clearable>-->
<!--          <el-option  label="吊车" :value="0"></el-option>-->
<!--          <el-option  label="登高车" :value="1"></el-option>-->
<!--        </el-select>-->
        <el-select v-model="SearchValue.status" placeholder="状态" size="small" style="width: 200px;margin-right: 5px;" clearable>
          <el-option  label="待提交" :value="0"></el-option>
          <el-option  label="正常" :value="1"></el-option>
          <el-option  label="撤场" :value="2"></el-option>
        </el-select>
        <el-input v-model="SearchValue.licensePlate" placeholder="请输入车牌号" size="small" style="width:200px;margin-right:15px;" clearable></el-input>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}" @selection-change="selectItem">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column align="center" type="index" label="序号" ></el-table-column>
        <el-table-column align="center" prop="coding" label="机械编号"></el-table-column>
        <el-table-column align="center" prop="machineLessor" label="机械租赁商名称"></el-table-column>
        <el-table-column align="center" prop="machineType" label="机械类型">
          <template slot-scope="{ row }">
            <span>{{ row.machineType ? '登高车' : '吊车' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="机械状态">
          <template slot-scope="{ row }">
            <span :class="{green: row.status == 1, red: row.status == 2, yellow: row.status == 0}">
              {{ row.status == 1 ? '正常' : row.status == 2 ? '撤场' : '待提交' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="specAndModel" label="规格型号"></el-table-column>
        <el-table-column align="center" prop="licensePlate" label="车牌号"></el-table-column>
        <el-table-column align="center" prop="departmentName" label="项目名称"></el-table-column>
        <el-table-column align="center" label="二维码">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" plain @click="topPrint(row)">打印</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top:10px;">
      <el-pagination
          v-if="dataTotal > SearchValue.pageSize"
          background
          :current-page.sync="SearchValue.pageCurrent"
          :page-size="SearchValue.pageSize"
          :total="dataTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>

    <!-- 详情 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" @closed="close()">
      <el-form ref="formData" :model="formData" class="demo-form-inline" label-width="100px" :rules="rules" style="margin-top: 20px;">
        <el-form-item label="机械类型" prop="machineType">
          <el-select v-model="formData.machineType" placeholder="机械类型" style="width: 100%;" disabled>
            <el-option  label="吊车" :value="0"></el-option>
            <el-option  label="登高车" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机械租赁商" prop="machineLessor">
          <el-select v-model="formData.machineLessor" placeholder="请选择机械租赁商" style="width: 100%;">
            <el-option v-for="item in MachineList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="规格型号" prop="specAndModel" maxlength="20" show-word-limit>
          <el-input v-model="formData.specAndModel" placeholder="请输入规格型号"></el-input>
        </el-form-item>
        <el-form-item label="车牌号" prop="licensePlate" maxlength="20" show-word-limit>
          <el-input v-model="formData.licensePlate" placeholder="请输入车牌号"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
        <el-button v-if="title== '新增' || formData.status == 0" type="warning" @click="submit" size="small">提 交</el-button>
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 打印二维码 -->
    <el-dialog title="打印二维码" v-if="isShowPrint" :visible.sync="isShowPrint" width="30%">
      <div class="flex-column" ref="imageWrapper" id="imageWrapper" style="width:100%;min-height:630px;background:#f2f2f2;padding-top:40px">
        <div class="qrcode-pic flex-center" ref="codeItem"></div>
        <div  class="flex-center title titles" style="font-size: 32px;">{{parentmsg.departmentName}}</div>
        <div style="display: flex;flex-direction: column">
          <div class="flex-center"  style="justify-content:space-around;margin-top:10px;">
            <div class="title flex-column" style="font-size: 32px;">
              <div>机械编码</div>
              <div>{{parentmsg.coding}}</div>
            </div>
            <div class="title flex-column" style="font-size: 32px;">
              <div>机械类型</div>
              <div>{{parentmsg.machineType ? '登高车' : '吊车' }}</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" v-print="'#printTest'" size="small">确 定</el-button> -->
<!--        <el-button type="primary" size="small" @click="changeType">切换方向</el-button>-->
        <el-button type="primary" size="small" @click="toImage">打 印</el-button>
        <el-button @click="isShowPrint = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '../api/web'
/* eslint-disable */
var api = new LPAPIWeb();
import html2canvas from 'html2canvas';
import {mapGetters} from "vuex"
import apis from '../api/nacelle-set.js'
import QRCode from "qrcodejs2";

export default {
  name: "four",

  components: {},

  props: {},

  data() {
    return {
      dataTotal: 0,
      tableData: [],
      multipleSelection: [],
      SearchValue: {
        machineLessor: '',
        machineType: 0,
        licensePlate: '',
        status: '',
        pageCurrent: 1,
        pageSize: 10
      },
      formData: {
        machineLessor: '',
        machineType: 0,
        specAndModel: '',
        licensePlate: ''
      },
      rules: {
        machineLessor: [
          { required: true, message: '请选择机械租赁商', trigger: 'change' }
        ],
        machineType: [
          { required: true, message: '请选择机械类型', trigger: 'change' }
        ],
        specAndModel: [
          { required: true, message: '请输入规格型号', trigger: 'blur' }
        ],
        licensePlate: [
          { required: true, message: '请输入车牌号', trigger: 'blur' }
        ],
      },
      dialogVisible: false,
      title: '',
      MachineList: [],
      isShowPrint: false,
      isShowDetails: false,
      parentmsg: {
        type:'上'
      }
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem']),
    departmentType(){
      return this.optionItem.departmentType
    },
    isProject(){
      return this.optionItem && this.optionItem.departmentType == 'project';
    },
    // 是否为集团人员
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    }
  },

  watch: {
    companyId() {
      this.init()
    }
  },

  mounted() {
    this.init()
    this.getMachineList()
    setTimeout(() => {
      this.updatePrinterList();
    }, 500)
  },

  methods: {
    getMachineList() {
      apis.machineLessorListAll().then(res => {
        this.MachineList = res
      })
    },
    // 选中
    selectItem(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection = newRows;
      } else {
        this.multipleSelection = rows;
      }
    },
    init() {
      if (!this.companyId) {
        setTimeout(() => {
          this.init()
        }, 100)
      } else {
        const params = {
          departmentId: this.optionItem.departmentId,
          departmentType: this.optionItem.departmentType,
          ...this.SearchValue
        }
        apis.largeMachinePage(params).then(data => {
          this.tableData = data.list
          this.dataTotal = data.total
        })
      }
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.SearchValue.pageCurrent = val
      this.init().async
    },
    search() {
      this.SearchValue.pageCurrent = 1
      this.init().async
    },
    add() {
      this.title = '新增'
      this.dialogVisible = true
    },
    edit() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一条数据')
      const data = this.multipleSelection[0]
      this.title = '修改'
      this.formData = JSON.parse(JSON.stringify(data))
      this.dialogVisible = true
    },
    del() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一条数据')
      const data = this.multipleSelection[0]
      this.$confirm('是否撤场该大型机械', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.delLM(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({type: 'info', message: '已取消'})
      })
    },
    close() {
      this.formData = {
        machineLessor: '',
        machineType: 0,
        specAndModel: '',
        licensePlate: ''
      }
      this.$refs.formData.resetFields()
    },
    confirm() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.title === '新增') {
            const params = JSON.parse(JSON.stringify(this.formData))
            params.departmentId = this.optionItem.departmentId
            apis.addLM(params).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init()
              this.dialogVisible = false
            })
          } else {
            apis.updateLM(this.formData).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init()
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    submit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          if (this.title === '新增') {
            const params = JSON.parse(JSON.stringify(this.formData))
            params.departmentId = this.optionItem.departmentId
            apis.subAddLM(params).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init()
              this.dialogVisible = false
            })
          } else {
            apis.subUpdateLM(this.formData).then(res => {
              console.log(res)
              this.$message.success('操作成功')
              this.init()
              this.dialogVisible = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 打印
    topPrint(row) {
      this.isShowPrint = true
      this.parentmsg = row
      this.parentmsg.type = '下'
      this.$nextTick(() => {
        this.$refs.codeItem.innerHTML = ''
        // const params = row.id+','+this.parentmsg.type
        const params = row.id+',大'
        new QRCode(this.$refs.codeItem, {
          text: String(params), //二维码内容
          width: 350,
          height: 350,
          // colorDark: "#333333", //二维码颜色
          // colorLight: "#ffffff", //二维码背景色
        })
      }, 500)
    },
    changeType(){
      if (this.parentmsg.type==='上'){
        this.$nextTick(()=>{
          this.parentmsg.type='下'
        })
        this.$set(this.parentmsg,'type','下')
      }else {
        this.parentmsg.type='上'
      }
      this.parentmsg=JSON.parse(JSON.stringify(this.parentmsg))
      this.$nextTick(() => {
        this.$refs.codeItem.innerHTML = ''
        const params = this.parentmsg.id+','+this.parentmsg.type
        new QRCode(this.$refs.codeItem, {
          text: String(params), //二维码内容
          width: 350,
          height: 350,
          // colorDark: "#333333", //二维码颜色
          // colorLight: "#ffffff", //二维码背景色
        })
      }, 500)
    },
    toImage() {
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUrl = dataURL;
        // console.log(this.imgUrl)
        const files = this.base64ImgtoFile(this.imgUrl)
        const formData = new FormData()
        formData.append('file',files)
        this.file2link(formData)
      });
    },
    async file2link(formData) {
      const data = await apis.uploadFile(formData)
      this.printImage(data)
      this.isShowPrint = false
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    /**
     * 更新打印机列表。
     */
    updatePrinterList() {
      document.createElement
      var printerElements = document.getElementById("select-printlist");
      var printers = api.getPrinters();
      if (printers instanceof Array && printers.length > 0) {
        for (var i = 0; i < printers.length; i++) {
          var item = printers[i];
          // 如果检测到局域网内的其他打印机，则可以获取ip和hostname，如果是本地打印机，则参数中只有name属性，表示打印机名称；
          var name = item.hostname ? item.name + "@" + item.hostname : item.name;
          var value = item.ip ? item.name + "@" + item.ip : item.name;
          printerElements.options.add(new Option(name, value));
        }
      } else {
        printerElements.options.add(new Option("未检测到打印机", ""));
      }
      // 链接默认打印机，并更新选中打印机打印参数；
      api.closePrinter();
    },
    printImage(url) {
      // 打开打印机
      if (!this.openPrinter())
        return;

      var width = 49;
      var height = 70;
      var url = url;
      var margin = 5;
      if (!api.startJob({width : width, height : height}))
        return false;

      api.drawImage({
        imageFile: url,
        x: margin,
        y: margin,
        width: width - margin * 2,
        height: height - margin * 2
      });
      api.commitJob();
      // 关闭打印机；
      api.closePrinter();
    },
    /**
     * 打开当前打印机；
     */
    openPrinter() {
      var printerElement = document.getElementById("select-printlist");
      if (!printerElement.value)
        return false;
      var arr = printerElement.value.split('@');
      var name = arr[0];
      var ip = arr[1];
      return api.openPrinter({ip: '127.0.0.1', printerName: name});
    }
  }
}
</script>

<style lang="stylus" scoped>
.content{
  height: 100%;
  padding: 10px 20px;
  .search,
  .operation {
    padding: 0 0 10px 0;
  }
  .red{
    color 	#DC143C
  }
  .yellow{
    color #FFD700
  }
  .green{
    color #32CD32
  }

}
</style>
